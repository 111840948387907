/// <reference types="vite/client" />
import "@kha/ui/main.css";
import "./window";
import "source-map-support/register";

import { fork } from "@kha/common";

import { settings } from "./core/settings";
import { stats } from "./core/stats";
import { initializeLogging, logger } from "./logger";
import { platform } from "./support/platform";

initializeLogging();

const start = async () => {
  const { createElement } = await import("react");
  const ReactDOM = await import("react-dom");
  const { Root } = await import("./components/Root");
  ReactDOM.render(createElement(Root), document.getElementById("root"));

  window.native?.log.allowUploads(!settings.disableTelemetry);

  logger.info(`Started ${platform}`);
  logStats();
};

window.addEventListener("load", async () => {
  try {
    await navigator.serviceWorker.register(
      import.meta.env.PROD
        ? "./service-worker.js"
        : new URL("./service-worker.ts", import.meta.url),
      { type: "module" }
    );
  } catch (error) {
    logger.warn("Service worker registration failed", error);
  }
});

window.addEventListener("online", () =>
  window.native?.log.indicateOnline(true)
);

window.addEventListener("offline", () =>
  window.native?.log.indicateOnline(false)
);

fork(start);

const logStats = () => {
  let frames = 0;
  let last = 0;
  const frame = () => {
    frames++;
    requestAnimationFrame(frame);
  };
  requestAnimationFrame(frame);

  setInterval(() => {
    const { usedJSHeapSize = 0, totalJSHeapSize = 0 } =
      (
        window.performance as unknown as {
          memory?: { usedJSHeapSize: number; totalJSHeapSize: number };
        }
      ).memory ?? {};

    const {
      usedHeapSize = () => 0,
      totalHeapSize = () => 0,
      cpuUsage = () => 0
    } = window.native ?? {};

    const now = performance.now();
    const fps = (frames / (now - last)) * 1000;
    last = now;
    frames = 0;

    stats.record({
      ...(platform === "electron" && {
        cpu: cpuUsage(),
        usedHeapSize: usedHeapSize(),
        totalHeapSize: totalHeapSize()
      }),
      usedJSHeapSize,
      totalJSHeapSize,
      fps
    });
  }, 5000);
};
